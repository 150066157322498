<template>
  <div>

    <div class="grey-text text-center odd-indicator">
      {{ alias }}
    </div>

    <div class="btn-wrapper">

      <button
          class="btn odd-btn opacity-85"
          v-bind:id="id"
          v-bind:unique="unique"
          v-bind:disabled="locked"
          @click="addJackpotPick(sport_id,match_id,market_name,market_id,home_team,away_team,pick)"
          v-bind:class="statusClass">

          <span
              v-if="!locked"
              v-bind:id="id"
              v-bind:oddStatus="oddStatus"
              v-bind:oddValue="odd"
              v-bind:oddActive="oddActive"
              v-bind:producerId="producer_id"
              v-bind:producerStatus="producerStatus">

              <span v-if="locked">-</span>
              <span v-else>{{ odd }}</span>

          </span>

          <span v-if="locked" style="height: 1.5em;">
            <LockIcon :size="iconSize" fill-color="black"></LockIcon>
          </span>

      </button>

    </div>

  </div>


</template>

<script>
    const LockIcon= () => import(/* webpackChunkName: "material-icons" */  'vue-material-design-icons/Lock.vue');

    export default {
        name: "Odd",
        props: {
            pick: {
                required: true
            },
            away_team: {
                required: true
            },
            home_team: {
                required: true
            },
            market_name: {
                required: true
            },
            sport_id: {
                required: true
            },
            show_direction: {
                required: false,
                default: true
            },
            previous_odds: {
                required: true
            },
            odds: {
                required: true
            },
            match_id: {
                required: true
            },
            market_id: {
                required: true
            },
            outcome_id: {
                required: true
            },
            specifier: {
                required: true
            },
            active: {
                required: true
            },
            status: {
                required: true
            },
            producer_id: {
                required: true
            },
            producer_status: {
                required: true
            },
            alias: {
                required: true,
                default: '',
            },
            unique: {
              required: true,
              default: '',
            }
        },
        methods: {
            oddsChange: function(payload){

                var vm = this;

                vm.oddStatus = payload.status;
                vm.oddActive = payload.active;

                if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

                    vm.previousOdds = vm.odd;
                    vm.odd = payload.odd;
                }

                vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
                vm.getStatusClass();

                vm.directionClass = vm.getOddDirectionClass();

            },
            getOddDirectionClass: function () {

                if(parseInt(this.producerStatus) === 0 || parseInt(this.oddActive) === 0 || parseInt(this.oddStatus) !== 0) {

                    return '';

                }

                if(parseFloat(this.odd) >= parseFloat(this.previousOdds) ) {

                    return 'arrow green up';

                }

                return 'arrow red down';

            },
            getStatusClass: function (){

                this.statusClass = this.locked ? 'lock' : '';

            }
        },
        mounted: function () {

            this.odd = this.odds;
            this.oddStatus = this.status;
            this.oddActive = this.active;
            this.previousOdds = this.previous_odds;
            this.producerStatus = this.producer_status;

            if(parseInt(this.producer_id) === 1 ) {

                this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0 || parseInt(this.producerStatus) === 0;

            } else {

                this.locked = parseInt(this.oddStatus) > 0 || parseInt(this.oddActive) === 0;

            }

            this.getStatusClass();

            this.id = this.getOddID(this.match_id,this.market_id,this.outcome_id,this.specifier,'jpodd');

            this.directionClass = this.getOddDirectionClass();
            var vm = this;

            this.EventBus.$on('odds:'+vm.id,function(payload){

                vm.oddStatus = payload.status;
                vm.oddActive = payload.active;

                if(parseFloat(payload.odd) !== parseFloat(vm.odd)) {

                    vm.previousOdds = vm.odd;
                    vm.odd = payload.odd;
                }

                vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(vm.producerStatus) === 0;
                vm.getStatusClass();

                vm.directionClass = vm.getOddDirectionClass();

            });

            this.EventBus.$on('producer:status',function(payload){

                var producer_id = payload.producer_id;
                var producer_status = payload.producer_status;

                if(parseInt(producer_id) === 1 ) {

                    vm.producerStatus = producer_status;
                    vm.directionClass = vm.getOddDirectionClass();
                    vm.locked = parseInt(vm.oddStatus) > 0 || parseInt(vm.oddActive) === 0 || parseInt(producer_status) === 0;
                    vm.getStatusClass();
                }

            });

            this.EventBus.$on('betstop:match-:'+vm.match_id,function(){

                vm.oddStatus = 1; // deactivate
                vm.locked = true;
                vm.getStatusClass();

            });

            this.EventBus.$on('status:match-:'+vm.match_id+':market-'+vm.market_id,function(payload){

                vm.oddStatus = payload.status; // deactivate
                vm.locked = parseInt(vm.oddStatus) > 0;
                vm.getStatusClass();

            });


        },
        data: function (){

            return {
                iconSize: 20,
                id: '',
                odd: '',
                directionClass: '',
                oddStatus: '',
                oddActive:'',
                previousOdds:'',
                producerStatus: '',
                locked: false,
                statusClass:'',
            }
        },
        components: {
            LockIcon
        }
    }
</script>
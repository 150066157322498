<template>
  <div>
    <div class="row m-0 p-0 py-1 mb-2">
      <div class="col-3 p-0 m-0 px-1 small-btn-padding text-center slip-col px-1">
        <a href="#" style="font-weight: 500;" class="login-button py-1 form-control small-text"
          @click="autoPick">Autopick</a>
      </div>

      <div class="col-3 p-0 m-0 px-1 small-btn-padding text-center slip-col px-1">
        <a href="" class="login-button py-1 d-none form-control small-text">Share</a>
      </div>

      <div class="col-4 p-0 m-0 px-0 small-btn-padding text-center text-yellow slip-col px-1">
        Picked:
        <span class="text-white"><span class="jp-counter" id="jp-counter">{{ betslip_count }}</span>
          out of {{ games }}</span>
      </div>

      <div class="col-2 p-0 m-0 px-0 small-btn-padding text-center slip-col px-1">
        <a href="#" class="text-white small-text clear-btn" @click="clearJP">Clear <img src="/img/icons/close.svg"
            width="15px"></a>
      </div>
    </div>

    <div class="spacer"></div>
    <section class="footer-betslip-section">
      <div class="d-flex justify-content-between wazi-primary mb-0">

        <div class="col-auto slip-slip">
          <div class="feature-icon-item-wrapper-slip1 pr-1 text-center">
            <router-link to="jackpot/JackpotResultsMB15">
              <img src="/img/FreeBetIcon.svg" class="slip-icon-svg" /> <br />
              <span class="text-white txt small-text">Results</span>
            </router-link>
          </div>
        </div>
        <div class="slip-slip pb-0 col-auto ">
          <!-- <span class="slip-text"> Betslip </span> -->
          <span class="jp-slip-counter" v-text="betslip_count"> 0 </span>
        </div>

        <div class="px-1 pt-2 mt-1 col-auto line-height-1">
          <div class="slip-btn-wrapper">
            <button class="slip-btn1 w-100 shadow" @click="placeJPBet">
              Place Bet
            </button>
          </div>
        </div>
      </div>

      <!-- </div> -->
    </section>
  </div>
</template>

<script>
import jpserve from "@/services/jpserve";
import fix from "@/services/fix";

export default {
  name: "bottom-navigation",
  props: {
    games: {
      required: true,
    },
    stake: {
      required: true,
    },
    // jackpotStatus: {
    //   required: true,
    // },
  },
  data: function () {
    return {
      loading: "",
      bestlip_visible: false,
      code: "",
      match_number: '',
      msg: "",
      share_odds: "",
      copyText: "Copy",
      copyBookingCode: "Click to copy",
      myProfile: this.getProfile(),
      accept_odds_changes: true,
      previous_odds: 0,
      odds_changed: false,
      seen: false,
      withholding_tax: 0,
      booking_code: "",
      picked_button: "",
    };
  },
  methods: {
    clearJP: function () {
      this.picked_button = "clear";
      this.clearJackpotBetSlip();
    },
    isPicked: function (share) {
      return share === this.picked_button ? "jp-selected-btn" : "jp-btn";
    },
    loadBookingCode: function () {
      if (this.booking_code.trim().length > 4) {
        if (this.currentRouteName !== "share") {
          this.$router.push({
            name: "share",
            params: { code: this.booking_code.trim() },
          });
        } else {
          this.EventBus.$emit("share:code", this.booking_code.trim());
        }
      } else {
        this.$toast.open({
          message: 'Please enter a valid booking code to proceed',
          type: 'error',
          position: 'top'
        });
      }
    },
    shareBetMessage: function () {
      var code = this.code;
      var odds = this.share_odds;
      var toWin = this.formatCurrency(parseFloat(odds) * 200);
      this.msg =
        "Cheki Hii bet nimeekelea kwa Tucheze.com. If you stake 200 and you could win Ksh. " +
        toWin +
        ". Betslip ndio Hii >> \n\n\n\n https://Tucheze.com.com/share/" +
        code;
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
    getSportAlt: function (sport_id) {
      switch (parseInt(sport_id)) {
        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/img/icons/dart.svg";

        case 20:
          return "/img/icons/tabletennis.svg";

        case 6:
          return "/img/icons/handball.svg";

        case 12:
          return "/img/icons/rugby.svg";

        case 10:
          return "/img/icons/boxing.svg";

        case 31:
          return "/img/icons/badminton.svg";

        case 3:
          return "/img/icons/baseball.svg";
      }

      return "";
    },
    show: function () {
      this.jQuery("#betslip-modal").modal("show");
      //var modal = document.getElementById("betslip-modal");
      //modal.style.display = "block";
      this.bestlip_visible = true;
    },
    placeJPBet: function () {
      this.picked_button = "bet";
      this.reset();
      var p = this.getAuth();


      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var bet_amount = this.getValue("stake");
      var booking_code = this.getValue("booking_code");
      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      //  

      if (bet_amount < 10) {
        this.$toast.open({
          message: 'Please enter bet amount greater of 10Ksh or more',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var betslipData = this.betslip;

      if (betslipData.total === 0) {
        this.$toast.open({
          message: 'Please Select atleast one outcome to continue',
          type: 'error',
          position: 'top'
        });
        return;
      }

      if (!this.accept_odds_changes) {
        this.$toast.open({
          message: 'Accept Odds Changes to Proceed',
          type: 'error',
          position: 'top'
        });
        return;
      }

      var bets = [];
      var match_number = 1; // Initialize match_number outside the loop

      this.jQuery.each(betslipData.picks, function (index, pick) {
        bets.push({
          match_number: match_number++,
          odd_id: parseInt(pick.odd_id),
          producer_id: 3,
        });
      });

      var data = {
        bet_type: this.isMobile() ? 2 : 1,
        bets: bets,
        booking_code: String(booking_code),
        campaign: String(utm_campaign),
        ip_address: String(''),
        medium: String(utm_medium),
        source: utm_source,
        stake: parseInt(bet_amount),
        stake_type: '',
        utm_source: utm_source,
      };

      // console.log("Payload>>>>>>>>>>>>>>>>", data);

      this.loading = "loading";

      var vm = this;

      var path = process.env.VUE_APP_BASE_JACKPOT_URL + '/jackpot/' + this.jackpot_id + '/bet'


      jpserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          // console.log("Response", res)
          var betID = res.data.bet_id;
          if (parseInt(res.data.error_code) === 0) {
            this.$toast.open({
              message: res.data.error_message,
              type: 'error',
              position: 'top'
            });
            return;
          }
          var message = res.data.data;
          // vm.setProfile(profile);
          vm.clearJackpotBetSlip();
          // vm.setSuccess("Success", message, betID);
          this.$toast.open({
            message: message, betID,
            type: 'success',
            position: 'top'
          });
          vm.removeObject("booking_code");
        })
        .catch((err) => {
          vm.clearJackpotBetSlip();
          // console.log("Failure Response", err)

          vm.loading = "";
          if (err.response) {
            var message = "";

            if (parseInt(err.response.status) === 428) {
              message = err.response.data.message.message;
              var amount = err.response.data.message.amount;

              vm.EventBus.$emit("deposit:popup", {
                amount: amount,
                message: message,
              });
            }

            if (parseInt(err.response.status) === 422) {
              message = err.response.data.error_message;
              this.$toast.open({
                message: message,
                type: 'error',
                position: 'top'
              });
              return;
            }

            if (
              parseInt(err.response.status) === 401 ||
              parseInt(err.response.status) === 400
            ) {
              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              // vm.logout();
              return;
            } else {
              message = err.response.data.error_message;
              this.$toast.open({
                message: message,
                type: 'error',
                position: 'top'
              });
            }
          } else if (err.request) {
            // console.log(JSON.stringify(err.request));
          } else {
            // console.log(JSON.stringify(err));
          }
        });
    },

    oddStatus: function () {
      var picks = this.betslip.picks;

      if (picks === undefined || picks.length === 0) {
        return;
      }

      var bets = [];

      this.jQuery.each(picks, function (k, v) {
        bets.push(v.odd_id + ":" + v.producer_id);
      });

      var data = {
        odd_id: bets.join(","),
      };

      if (bets.length === 0) {
        return;
      }

      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/odd/status";

      fix
        .post(path, JSON.stringify(data))
        .then((res) => {
          var bets = res.data.message;

          vm.jQuery.each(bets, function (k, v) {
            var oddID = v.id;
            var odds = v.odds;
            var previous_odds = v.previous_odds;
            var status = v.status;
            var active = v.active;
            var producer_status = v.producer_status;

            // get original odds
            // check odds changes
            vm.jQuery.each(picks, function (k1, v1) {
              if (parseInt(v1.odd_id) === parseInt(oddID)) {
                var oddChangeText = "";
                odds = vm.formatOdds(odds);

                if (odds !== vm.formatOdds(v1.odd)) {
                  // odds changed
                  if (odds > v1.odd) {
                    var direction = 1;
                  } else {
                    direction = 0;
                  }

                  var directionTxt =
                    parseInt(direction) === 1
                      ? "odds has gone up "
                      : "odds has gone down ";
                  oddChangeText =
                    directionTxt + " from " + v1.odd + " to " + odds;
                }

                v1.status = status;
                v1.active = active;
                v1.odds = odds;
                v1.odd = odds;
                v1.previous_odds = previous_odds;
                v1.producer_status = producer_status;
                v1.odds_change_text = oddChangeText;
                picks[k1] = v1;
              }
            });
          });

          var pk = [];

          vm.jQuery.each(picks, function (k1, v1) {
            pk.push(v1);
          });

          vm.saveObject("jslip", pk);
          // vm.autoRefreshJackpotUI(vm.$vnode.tag);
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.open({
              message: err.response.data.message,
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err.request));
          } else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            // console.log(JSON.stringify(err));
          }
        });
    },
    getOddStatus: function (odd) {
      if (odd.active === 0 || odd.producer_status === 0) {
        return '<div class="deactivated-odds">Outcome suspended</div>';
      }

      if (parseInt(odd.status) === 0 || parseInt(odd.status) === -1) {
        return '<div class="deactivated-odds">Outcome Deactivated</div>';
      }

      if (odd.odds_change_text && odd.odds_change_text.length > 0) {
        return (
          '<div class="odds-change" style="">' + odd.odds_change_text + "</div>"
        );
      }
    },
    showBetslip: function () {
      document.getElementById("betslip-init").click();
    },
    autoPick: function () {
      this.picked_button = "auto";

      this.clearJackpotBetSlip();
      var max = 17;
      var r = 0;
      while (r < max) {
        // generate random between 0 and 2
        var i = this.getRandomInt(3);
        var id = "r" + r + "c" + i;
        // console.log('Wants to click ' + id);
        //this.jQuery("#"+id).click();
        this.jQuery("[unique=" + id + "]").click();
        r++;
      }
    },
  },

  computed: {
    jackpot_id: function () {
      return this.$store.state.jackpot_id;
    },
    currentRouteName() {
      return this.$route.name;
    },
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },

    iconSize: function () {
      return 28;
    },
    profile: function () {
      return this.myProfile;
    },
    liveGames: function () {
      return this.$store.state.live_match_counter;
    },
    homePageIcon: function () {
      if (this.page === "home") {
        return this.getURL("/img/home_icon_selected.svg");
      }

      return this.getURL("/img/home_icon.svg");
    },
    homePageFontColor: function () {
      if (this.page === "home") {
        return "yellow-txt";
      }

      return "";
    },
    livePageFontColor: function () {
      if (this.page === "live") {
        return "yellow-txt";
      }

      return "";
    },
    historyPageFontColor: function () {
      if (this.page === "history") {
        return "yellow-txt";
      }

      return "";
    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },
    activeBets: function () {
      var p = this.getProfile();
      if (!p) {
        return 0;
      }

      return p.b;
    },
    odds: function () {
      //return this.betslip.odds;
      return this.$store.state.betslip.odds
    },
    payout: function () {
      return this.formatCurrency(this.betslip.payout);
    },
    betslip: function () {
      return this.$store.state.jackpotbetslip;
    },
    has_suspended_picks: function () {
      return this.$store.state.has_suspended_jackpot_picks;
    },
    isLoggedIn: function () {
      var p = this.getProfile();
      if (!p) {
        return false;
      }

      return true;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "KES",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
  watch: {
    stake: function (newValue) {
      // update stake
      this.setValue("stake", newValue);

      // recalculate winnings
      this.autoRefreshJackpotUI(this.$vnode.tag);
    },
  },
  components: {},
  mounted: function () {
    // console.log("Here are jackport games", this.games)
    // reset stake
    this.setValue("stake", this.stake);
    this.previous_odds = this.betslip.odds;

    this.$store.dispatch("getLiveMatchCounter");
    var vm = this;

    this.EventBus.$on("event:jackpotbetslip:placeBet", function () {
      vm.placeBet(true);
    });

    this.EventBus.$on("profile:balance", function (payload) {
      vm.myProfile = payload;
    });

    this.EventBus.$on("jackpotodds:changed", function (payload) {
      vm.odds_changed = true;
      vm.updateJackpotOdd(
        payload.id,
        payload.odds,
        payload.previous_odds,
        payload.active,
        payload.status
      );
    });
  },
};
</script>
